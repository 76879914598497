/**
 * Application entry point.
 *
 * This file includes `import`'s for all the components/directives used
 * by the HTML page and the controller to provide the configuration.
 */

import './sass/desktop.scss';
import 'gmf/controllers/desktop.scss';
import './sass/vars_desktop.scss';

import "@fortawesome/fontawesome-free/scss/brands.scss";

import angular from 'angular';
import gmfControllersAbstractDesktopController, {AbstractDesktopController}
  from 'gmf/controllers/AbstractDesktopController.js';
import ngeoGeolocationDesktop from 'ngeo/geolocation/desktop.js';
import config from 'gmf/controllers/AbstractAppController.js';
import schwyzBase from '../schwyzmodule.js';
import EPSG2056 from '@geoblocks/proj/src/EPSG_2056.js';
import EPSG21781 from '@geoblocks/proj/src/EPSG_21781.js';
import Raven from 'raven-js/src/raven.js';
import RavenPluginsAngular from 'raven-js/plugins/angular.js';

import olStyleCircle from 'ol/style/Circle.js';
import olStyleFill from 'ol/style/Fill.js';
import olStyleStroke from 'ol/style/Stroke.js';
import olStyleStyle from 'ol/style/Style.js';

import egidPersonListModule from '../components/egidpersonlist/service.js';
import Style from 'ol/style/Style.js';
import Stroke from 'ol/style/Stroke.js';
import olStyleRegularShape from 'ol/style/RegularShape.js';
import {COORDINATES_LAYER_NAME} from 'gmf/index.js';

if (!window.requestAnimationFrame) {
  alert('Your browser is not supported, please update it or use another one. You will be redirected.\n\n'
    + 'Votre navigateur n\'est pas supporté, veuillez le mettre à jour ou en utiliser un autre. '
    + 'Vous allez être redirigé.\n\n'
    + 'Ihr Browser wird nicht unterstützt, bitte aktualisieren Sie ihn oder verwenden Sie einen anderen. '
    + 'Sie werden weitergeleitet.');
  window.location.href = 'https://geomapfish.org/';
}


/**
 * @private
 */
class Controller extends AbstractDesktopController {
  /**
   * @param {import('gmf/controllers/AbstractAppController.js').Config} config A part of the application
   *     config.
   * @param {angular.IScope} $scope Scope.
   * @param {angular.auto.IInjectorService} $injector Main injector.
   * @param {import('../components/egidpersonlist/service.js').EgidPersonListService} geoportalEgidPersonListService Service for person lists.
   */
  constructor($scope, $injector, geoportalEgidPersonListService) {
    super({
      srid: 2056,
      mapViewConfig: {
        center: [2697600, 1213375],
        zoom: 0,
        resolutions: [50, 20, 10, 5, 2.5, 2, 1.5, 1, 0.5, 0.25, 0.1, 0.05]
      }
    }, $scope, $injector);

    /**
     * @type {Array<string>}
     */
    this.searchCoordinatesProjections = [EPSG21781, EPSG2056, 'EPSG:4326'];

    /** 
    * @type {Object<string, import('ol/style/Style.js').default>} Map of styles for search overlay. 
    * @export 
    */ 
    this.searchStyles = {};
    
    /** 
    * @type {Object<string, import("ol/style/Style.js").default>} 
    */ 
    this.searchStyles[COORDINATES_LAYER_NAME] = new Style({
    image: new olStyleRegularShape({
    stroke: new Stroke({color: [255, 0, 0, 1.2], width: 2}),
    points: 4,
    radius: 8,
    radius2: 0,
    angle: 0,
    }),
    }); 
    
 
   /**
     * @type {!Array<number>}
     */
    this.scaleSelectorValues = [200000, 75000, 40000, 20000, 10000, 7500, 5000, 3500, 2000, 1000, 500, 200];

    /**
     * @type {Array<string>}
     */
    this.elevationLayers = ['aster', 'srtm'];

    /**
     * @type {Object<string, import('gmf/mobile/measure/pointComponent.js').LayerConfig>}
     */
    this.elevationLayersConfig = {};

    /**
     * @type {string}
     */
    this.selectedElevationLayer = this.elevationLayers[0];

    /**
     * @type {Object<string, import('gmf/profile/component.js').ProfileLineConfiguration>}
     */
    this.profileLinesconfiguration = {
      'aster': {color: '#0000A0'},
      'srtm': {color: '#00A000'},
    };

    /**
     * @type {Array<import('gmf/map/mousepositionComponent.js').MousePositionProjection>}
     */
    this.mousePositionProjections = [{
      code: EPSG2056,
      label: 'CH1903+ / LV95',
      filter: 'ngeoNumberCoordinates::{x}, {y} m'
    }, {
      code: EPSG21781,
      label: 'CH1903 / LV03',
      filter: 'ngeoNumberCoordinates::{x}, {y} m'
    }, {
      code: 'EPSG:4326',
      label: 'WGS84',
      filter: 'ngeoDMSCoordinates:2'
    }];

    /**
     * @type {Object.<string, string|number|boolean>}
     * @export
     */
    this.printFieldvalues = {
      'legend': false
    };

    // Allow angular-gettext-tools to collect the strings to translate
    /** @type {angular.gettext.gettextCatalog} */
    const gettextCatalog = $injector.get('gettextCatalog');
    gettextCatalog.getString('Add a theme');
    gettextCatalog.getString('Add a sub theme');
    gettextCatalog.getString('Add a layer');

    if ($injector.has('sentryUrl')) {
      const options = $injector.has('sentryOptions') ? $injector.get('sentryOptions') : undefined;
      const raven = new Raven();
      raven.config($injector.get('sentryUrl'), options)
        .addPlugin(RavenPluginsAngular)
        .install();
    }

    const positionFeatureStyle = config.positionFeatureStyle || new olStyleStyle({
      image: new olStyleCircle({
        radius: 6,
        fill: new olStyleFill({color: 'rgba(230, 100, 100, 1)'}),
        stroke: new olStyleStroke({color: 'rgba(230, 40, 40, 1)', width: 2})
      })
    });

    const accuracyFeatureStyle = config.accuracyFeatureStyle || new olStyleStyle({
      fill: new olStyleFill({color: 'rgba(100, 100, 230, 0.3)'}),
      stroke: new olStyleStroke({color: 'rgba(40, 40, 230, 1)', width: 2})
    });

    /**
     * @type {import('ngeo/geolocation/desktop.js').DesktopGeolocationDirectiveOptions}
     */
     this.desktopGeolocationOptions = {
      positionFeatureStyle: positionFeatureStyle,
      accuracyFeatureStyle: accuracyFeatureStyle,
      zoom: config.geolocationZoom,
      autorotate: config.autorotate
    };

    // Custom variable for logo
    this.logoUrl = $injector.get('logoUrl');
    this.faviconUrl = $injector.get('faviconUrl');

    // initialize EgidPersonList links
    geoportalEgidPersonListService.init();
  }
}

/**
 * @hidden
 */
const module = angular.module('Appdesktop', [
  schwyzBase.name,
  gmfControllersAbstractDesktopController.name,
  egidPersonListModule.name,
  ngeoGeolocationDesktop.name,
  config.name
]);

module.value('gmfContextualdatacontentTemplateUrl', 'gmf/contextualdata');
module.run(/* @ngInject */ ($templateCache) => {
  // @ts-ignore: webpack
  $templateCache.put('gmf/contextualdata', require('./contextualdata.html'));
});

module.controller('DesktopController', Controller);

export default module;
