import angular from 'angular';


/**
 * EgidPersonListService
 * Provides support for the old GXP customization, which provided an
 * EgidPersonList popup when clicking on a EgidPersonList link.
 * @constructor
 * @struct
 * @param {angular.$injector} $injector Main injector.
 * @param {import("ngeo/message/Popup.js").PopupFactory} ngeoCreatePopup Popup service.
 * @ngInject
 * @ngdoc service
 * @ngname geoportalEgidPersonListService
 */
export function EgidPersonListService($injector, ngeoCreatePopup) {
  /**
   * @type {angular.$injector}
   * @private
   */
  this.$injector_ = $injector;

  /**
   * @type {ngeo.CreatePopup}
   * @private
   */
  this.createPopup_ = ngeoCreatePopup;
};


/**
 * @export
 */
EgidPersonListService.prototype.init = function(){
  console.log('init');
  if (this.$injector_.has('geoportalFeuerwehrEgidUrl')) {
    const egidUrl = this.$injector_.get('geoportalFeuerwehrEgidUrl');
    console.log('has value: ' + egidUrl);

    window['schwyz'] = window['schwyz'] || {};
    window['schwyz']['egidPersonsListWin'] = (egid) => {
      const popup = this.createPopup_();
      popup.setAutoDestroy(true);
      popup.addClass('popup-with-iframe');
      popup.setTitle('Personen-Liste');
      popup.setUrl(egidUrl.replace('9999', egid));
      popup.setSize('60%', '60%');
      popup.setOpen(true);
    };
  } else {
    console.warn('geoportalFeuerwehrEgidUrl is not defined');
  }
};

const module = angular.module('egidPersonListModule', []);

module.service('geoportalEgidPersonListService', EgidPersonListService);
export default module;
